<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
          <CIcon name="cil-justify-center"/>
            <strong> Daily Time Records</strong>
              <div class="card-header-actions">
                <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
                >
                <small class="text-muted">DTR</small>
                </a>
              </div>
          </CCardHeader>
           <CCardBody>
             <div>
              <div>
                    <div class="row">
                        <div class="form-group">
                          <span class="ml-2">From: </span>
                          <input class="ml-2" type="date" v-model="from"/>
                        </div>
                        <div class="form-group">
                          <span class="ml-2">To: </span>
                          <input class="ml-2" type="date" v-model="to"/>
                        </div>
                        <button class="ml-2 btn btn-primary btn-sm" @click="searchLogs(from, to)" style="height:27px">Search</button>

                      <button class="ml-auto mr-3 btn btn-primary btn-sm" style="height:27px" @click="printForm">Print</button>
                    </div>
                    <div class="mt-4 ml-4">
                      <div id="dtr">
                        <div class="text-right">
                            <h6>Ref. No. {{reference()}}</h6>
                        </div>
                          <div class="text-center">
                            <h6 style="font-style: italic">Civil Service Form No. 48</h6>
                            <h4>DAILY TIME RECORD</h4>
                            <h4><span style="border-bottom: 1px solid">{{fullname}}</span></h4>
                            <p style="font-size: 18px">(Name)</p>
                          </div>
                          <div class="d-flex justify-content-center">
                            <div class="text-left">
                              <p style="font-size: 18px">For the Month of <span style="border-bottom: 1px solid">{{getDate()}}</span></p>
                              <p style="font-size: 18px; margin-top: -15px">Official hours for arrival  <span style="margin-left: 100px">Regular days __________</span></p>
                              <p style="font-size: 18px; margin-top: -15px">and departure  <span style="margin-left: 173px">Saturdays __________</span></p>
                            </div>
                          </div>
                          <div>
                            <div class="row">
                              <div class="col-md-1"></div>
                              <div class="col-md-1" style="border: 1px solid;border-bottom:none">
                                Day
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left: none">
                                A.M.
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none">
                                P.M.
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none">
                                Undertime
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none">
                                Total Hours Render
                              </div>
                              
                            </div>
                            <div class="row">
                              <div class="col-md-1"></div>
                              <div class="col-md-1" style="border: 1px solid;border-top:none">
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                <div class="row">
                                    <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">Arrival</div>
                                    <div class="col-md-6">Departure</div>
                                </div>
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                  <div class="row">
                                    <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">Arrival</div>
                                    <div class="col-md-6">Departure</div>
                                </div>
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                <div class="row">
                                    <div class="col-md-12">Hours | Minutes</div>
                                </div>
                              </div>
                              <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                <div class="row">
                                    <div class="col-md-12">Hours | Minutes</div>
                                </div>
                              </div>
                              <div class="col-md-1"></div>
                              </div>
                              <!--1-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  1
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '01')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{
                                        tConvert(getCheckInArrival(years + '-' + months + '-' + '01'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '01')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '01')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '01')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours1(years + '-' + months + '-' + '01')) === 'NO LATE' || convertToWords(totalRenderHours1(years + '-' + months + '-' + '01')) === 'SATURDAY' || convertToWords(totalRenderHours1(years + '-' + months + '-' + '01')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours1(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '01')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours1())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--2-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  2
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '02')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '02'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '02')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '02')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '02')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours2(years + '-' + months + '-' + '02')) === 'NO LATE' || convertToWords(totalRenderHours2(years + '-' + months + '-' + '02')) === 'SATURDAY' || convertToWords(totalRenderHours2(years + '-' + months + '-' + '02')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours2(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '02')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours2())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--3-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  3
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '03')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '03'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '03')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '03')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '03')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours3(years + '-' + months + '-' + '03')) === 'NO LATE' || convertToWords(totalRenderHours3(years + '-' + months + '-' + '03')) === 'SATURDAY' || convertToWords(totalRenderHours3(years + '-' + months + '-' + '03')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours3(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '03')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours3())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--4-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  4
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '04')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '04'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '04')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '04')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '04')) === 'SUNDAY' ? 'SUNDAY' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours4(years + '-' + months + '-' + '04')) === 'NO LATE' || convertToWords(totalRenderHours4(years + '-' + months + '-' + '04')) === 'SATURDAY' || convertToWords(totalRenderHours4(years + '-' + months + '-' + '04')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours4(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '04')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours4())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--5-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  5
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '05')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '05'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '05')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '05')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '05')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours5(years + '-' + months + '-' + '05')) === 'NO LATE' || convertToWords(totalRenderHours5(years + '-' + months + '-' + '05')) === 'SATURDAY' || convertToWords(totalRenderHours5(years + '-' + months + '-' + '05')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours5(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '05')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours5())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--6-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  6
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '06')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '06'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '06')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '06')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '06')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours6(years + '-' + months + '-' + '06')) === 'NO LATE' || convertToWords(totalRenderHours6(years + '-' + months + '-' + '06')) === 'SATURDAY' || convertToWords(totalRenderHours6(years + '-' + months + '-' + '06')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours6(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '06')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours6())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--7-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  7
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '07')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '07'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '07')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '07')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '07')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours7(years + '-' + months + '-' + '07')) === 'NO LATE' || convertToWords(totalRenderHours7(years + '-' + months + '-' + '07')) === 'SATURDAY' || convertToWords(totalRenderHours7(years + '-' + months + '-' + '07')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours7(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '07')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours7())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--8-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  8
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '08')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '08'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '08')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '08')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '08')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours8(years + '-' + months + '-' + '08')) === 'NO LATE' || convertToWords(totalRenderHours8(years + '-' + months + '-' + '08')) === 'SATURDAY' || convertToWords(totalRenderHours8(years + '-' + months + '-' + '08')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours8(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '08')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours8())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--9-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  9
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '09')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '09'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '09')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '09')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '09')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours9(years + '-' + months + '-' + '09')) === 'NO LATE' || convertToWords(totalRenderHours9(years + '-' + months + '-' + '09')) === 'SATURDAY' || convertToWords(totalRenderHours9(years + '-' + months + '-' + '09')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours9(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '09')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours9())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--10-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  10
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '10')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '10'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '10')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '10')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '10')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours10(years + '-' + months + '-' + '10')) === 'NO LATE' || convertToWords(totalRenderHours10(years + '-' + months + '-' + '10')) === 'SATURDAY' || convertToWords(totalRenderHours10(years + '-' + months + '-' + '10')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours10(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '10')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours10())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--11-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  11
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '11')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '11'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '11')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '11')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '11')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours11(years + '-' + months + '-' + '11')) === 'NO LATE' || convertToWords(totalRenderHours11(years + '-' + months + '-' + '11')) === 'SATURDAY' || convertToWords(totalRenderHours11(years + '-' + months + '-' + '11')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours11(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '11')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours11())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--12-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  12
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '12')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '12'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '12')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '12')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '12')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours12(years + '-' + months + '-' + '12')) === 'NO LATE' || convertToWords(totalRenderHours12(years + '-' + months + '-' + '12')) === 'SATURDAY' || convertToWords(totalRenderHours12(years + '-' + months + '-' + '12')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours12(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '12')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours12())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--13-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  13
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '13')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '13'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '13')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '13')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '13')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours13(years + '-' + months + '-' + '13')) === 'NO LATE' || convertToWords(totalRenderHours13(years + '-' + months + '-' + '13')) === 'SATURDAY' || convertToWords(totalRenderHours13(years + '-' + months + '-' + '13')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours13(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '13')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours13())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--14-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  14
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '14')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '14'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '14')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '14')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '14')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours14(years + '-' + months + '-' + '14')) === 'NO LATE' || convertToWords(totalRenderHours14(years + '-' + months + '-' + '14')) === 'SATURDAY' || convertToWords(totalRenderHours14(years + '-' + months + '-' + '14')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours14(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '14')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours14())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--15-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  15
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '15')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '15'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '15'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '15'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '15')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '15')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '15')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '15'))}}</div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours15(years + '-' + months + '-' + '15')) === 'NO LATE' || convertToWords(totalRenderHours15(years + '-' + months + '-' + '15')) === 'SATURDAY' || convertToWords(totalRenderHours15(years + '-' + months + '-' + '15')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours15(years + '-' + months + '-' + '15'))}}</div>
                                </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '15')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours15())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--16-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  16
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '16')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '16'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '16')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '16')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '16')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours16(years + '-' + months + '-' + '16')) === 'NO LATE' || convertToWords(totalRenderHours16(years + '-' + months + '-' + '16')) === 'SATURDAY' || convertToWords(totalRenderHours16(years + '-' + months + '-' + '16')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours16(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '16')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours16())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--17-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  17
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '17')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '17'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '17')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '17')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '17')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours17(years + '-' + months + '-' + '17')) === 'NO LATE' || convertToWords(totalRenderHours17(years + '-' + months + '-' + '17')) === 'SATURDAY' || convertToWords(totalRenderHours17(years + '-' + months + '-' + '17')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours17(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '17')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours17())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--18-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  18
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '18')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '18'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '18')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '18')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '18')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours18(years + '-' + months + '-' + '18')) === 'NO LATE' || convertToWords(totalRenderHours18(years + '-' + months + '-' + '18')) === 'SATURDAY' || convertToWords(totalRenderHours18(years + '-' + months + '-' + '18')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours18(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '18')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours18())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--19-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  19
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '19')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '19'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '19')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '19')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '19')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours19(years + '-' + months + '-' + '19')) === 'NO LATE' || convertToWords(totalRenderHours19(years + '-' + months + '-' + '19')) === 'SATURDAY' || convertToWords(totalRenderHours19(years + '-' + months + '-' + '19')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours19(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '19')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours19())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--20-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  20
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '20')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '20'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '20')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '20')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '20')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours20(years + '-' + months + '-' + '20')) === 'NO LATE' || convertToWords(totalRenderHours20(years + '-' + months + '-' + '20')) === 'SATURDAY' || convertToWords(totalRenderHours20(years + '-' + months + '-' + '20')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours20(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '20')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours20())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--21-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  21
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '21')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '21'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '21')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '21')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '21')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours21(years + '-' + months + '-' + '21')) === 'NO LATE' || convertToWords(totalRenderHours21(years + '-' + months + '-' + '21')) === 'SATURDAY' || convertToWords(totalRenderHours21(years + '-' + months + '-' + '21')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours21(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '21')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours21())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--22-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  22
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '22')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '22'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '22')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '22')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '22')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours22(years + '-' + months + '-' + '22')) === 'NO LATE' || convertToWords(totalRenderHours22(years + '-' + months + '-' + '22')) === 'SATURDAY' || convertToWords(totalRenderHours22(years + '-' + months + '-' + '22')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours22(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '22')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours22())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--23-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  23
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '23')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '23'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '23')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '23')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '23')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours23(years + '-' + months + '-' + '23')) === 'NO LATE' || convertToWords(totalRenderHours23(years + '-' + months + '-' + '23')) === 'SATURDAY' || convertToWords(totalRenderHours23(years + '-' + months + '-' + '23')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours23(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '23')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours23())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--24-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  24
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '24')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '24'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '24')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '24')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '24')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours24(years + '-' + months + '-' + '24')) === 'NO LATE' || convertToWords(totalRenderHours24(years + '-' + months + '-' + '24')) === 'SATURDAY' || convertToWords(totalRenderHours24(years + '-' + months + '-' + '24')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours24(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '24')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours24())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--25-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  25
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '25')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '25'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '25')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '25')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '25')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours25(years + '-' + months + '-' + '25')) === 'NO LATE' || convertToWords(totalRenderHours25(years + '-' + months + '-' + '25')) === 'SATURDAY' || convertToWords(totalRenderHours25(years + '-' + months + '-' + '25')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours25(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '25')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours25())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--26-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  26
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '26')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '26'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '26')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '26')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '26')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours26(years + '-' + months + '-' + '26')) === 'NO LATE' || convertToWords(totalRenderHours26(years + '-' + months + '-' + '26')) === 'SATURDAY' || convertToWords(totalRenderHours26(years + '-' + months + '-' + '26')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours26(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '26')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours26())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--27-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  27
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '27')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '27'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '27')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '27')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '27')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours27(years + '-' + months + '-' + '27')) === 'NO LATE' || convertToWords(totalRenderHours27(years + '-' + months + '-' + '27')) === 'SATURDAY' || convertToWords(totalRenderHours27(years + '-' + months + '-' + '27')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours27(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '27')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours27())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--28-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  28
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '28')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '28'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '28')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '28')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '28')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours28(years + '-' + months + '-' + '28')) === 'NO LATE' || convertToWords(totalRenderHours28(years + '-' + months + '-' + '28')) === 'SATURDAY' || convertToWords(totalRenderHours28(years + '-' + months + '-' + '28')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours28(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '28')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours28())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--29-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  29
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '29')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '29'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '29')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '29')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '29')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours29(years + '-' + months + '-' + '29')) === 'NO LATE' || convertToWords(totalRenderHours29(years + '-' + months + '-' + '29')) === 'SATURDAY' || convertToWords(totalRenderHours29(years + '-' + months + '-' + '29')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours29(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '29')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours29())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--30-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  30
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '30')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '30'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '30')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '30')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '30')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours30(years + '-' + months + '-' + '30')) === 'NO LATE' || convertToWords(totalRenderHours30(years + '-' + months + '-' + '30')) === 'SATURDAY' || convertToWords(totalRenderHours30(years + '-' + months + '-' + '30')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours30(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '30')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours30())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--31-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  31
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '31')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '31'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(checkUnderTime(years + '-' + months + '-' + '31')) === 'NO LATE' || convertToWords(checkUnderTime(years + '-' + months + '-' + '31')) === 'SATURDAY' || convertToWords(checkUnderTime(years + '-' + months + '-' + '31')) === 'SUNDAY' ? '' : '']">{{convertToWords(checkUnderTime(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12" :class="[convertToWords(totalRenderHours31(years + '-' + months + '-' + '31')) === 'NO LATE' || convertToWords(totalRenderHours31(years + '-' + months + '-' + '31')) === 'SATURDAY' || convertToWords(totalRenderHours31(years + '-' + months + '-' + '31')) === 'SUNDAY' ? '' : '']">{{convertToWords(totalRenderHours31(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="row" v-show="checkDateForHide(years + '-' + months + '-' + '31')">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none;border-right: none;">
                                  
                                </div>
                                <div class="col-md-2 text-center" style="border: 1px solid; border-left: none; border-top:none; border-right: none">
                                  <div class="row">
                                  </div>
                                </div>
                                <div class="col-md-2" style="border: 1px solid; border-left:none; border-top: none;">
                                    <div>
                                      <small class="d-flex justify-content-end font-weight-bold">TOTAL WEEKLY HOURS</small>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center" style="border: 1px solid; border-left:none;;background-color: #ddd">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(totalWeeklyRenderHours31())}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--total-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1">
                                </div>
                                <div class="col-md-2 text-center">
                                  <div class="row">
                                      <div class="col-md-6"></div>
                                      <div class="col-md-6"></div>
                                  </div>
                                </div>
                                <div class="col-md-2 text-center">
                                    <div class="row">
                                      <div class="col-md-6"></div>
                                      <div class="col-md-6"></div>
                                  </div>
                                </div>
                                <!-- <div class="col-md-2 text-right">
                                    <div class="row">
                                      <div class="col-md-12"><span style="font-weight:bold">Total:</span> <span class="">{{ convertToWords(totalUndertime()) }}</span></div>
                                  </div>
                                </div> -->
                                <div class="col-md-1"></div>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p class="text-left mt-2" style="max-width: 330px">I CERTIFY on my honor that the above is a true and correct report of the hours of work performed record of which was made daily at the time of arrival at departure from office.</p>             
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="margin-left:-250px">Prepared by: </p> 
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="font-weight: bold; border-bottom: 1px solid">{{fullname}}</p>
                              </div>
                              <div class="d-flex justify-content-center" style="margin-top: -10px">
                                <p>Printed Name & Signature</p>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="margin-left:-70px">VERIFIED as to the prescribed office hours </p>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 14 || $route.params.pID == 63">AMY C. SAAVEDRA, MSCA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 1 || $route.params.pID == 13">GEORGE M. COLORADO, PhD, CESO III</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 19 || $route.params.pID == 31 || $route.params.pID == 32 || $route.params.pID == 34 || $route.params.pID == 36 || $route.params.pID == 37 || $route.params.pID == 21 || $route.params.pID == 27 || $route.params.pID == 57 || $route.params.pID == 61 || $route.params.pID == 52 || $route.params.pID == 38 || $route.params.pID == 15 || $route.params.pID == 67">JULIA FELISA C. MARTINEZ, LPT, PhD</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 39 || $route.params.pID == 40 || $route.params.pID == 41 || $route.params.pID == 49 || $route.params.pID == 30 || $route.params.pID == 65 || $route.params.pID == 70">NOEME ROSE D. MANI, CPA, MM</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 44 || $route.params.pID == 60 || $route.params.pID == 23">HAEMACCEL I. PALIMA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 55 || $route.params.pID == 69 || $route.params.pID == 47">FRANCIS D. ILAGAN</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 17 || $route.params.pID == 68">CRISELDO C. CALINAWAN, PHD, PECE</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 6 || $route.params.pID == 7 || $route.params.pID == 9 || $route.params.pID == 18 || $route.params.pID == 8 || $route.params.pID == 53 || $route.params.pID == 51 || $route.params.pID == 50">GESSELE M. FRANCISCO, CPA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 56 || $route.params.pID == 42 || $route.params.pID == 58">ANGEL A. ASIS</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 2 || $route.params.pID == 3 || $route.params.pID == 4 || $route.params.pID == 5 || $route.params.pID == 26 || $route.params.pID == 16 || $route.params.pID == 28">ALMA S. PATRON, LLB, MSW</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="$route.params.pID == 11 || $route.params.pID == 43 || $route.params.pID == 46 || $route.params.pID == 48 || $route.params.pID == 22 || $route.params.pID == 66">REVELYN P. BRINA, MAED</p>
                              </div>
                              <div class="d-flex justify-content-center" style="margin-top: -10px">
                                <p>Printed Name & Signature</p>
                              </div>
                      </div>
                    </div>
                </div>
             </div>
           </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped>
 .col-md-6, .col-md-12 {
   font-size: 12px;
 }

</style>
<script>
// Import VueHTMLPaper
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}
Vue.use(VueHtmlToPaper, options);

var tt="", d1="",d2="",d3="",d4="",d5="",d6="",d7="",d8="",d9="",d10="",d11="",d12="",d13="",d14="",d15="",
d16="",d17="",d18="",d19="",d20="",d21="",d22="",d23="",d24="",d25="",d26="",d27="",d28="",d29="",d30="",d31="",
td1="",td2="",td3="",td4="",td5="",td6="",td7="",td8="",td9="",td10="",td11="",td12="",td13="",td14="",td15="",
td16="",td17="",td18="",td19="",td20="",td21="",td22="",td23="",td24="",td25="",td26="",td27="",td28="",td29="",td30="",td31="";

 
export default {
  name: 'IndividualDTR',
  data() {
    return {
      individual_logs: [],
      from: "",
      to: "",
      months: "",
      years: "",      
      fullname: "",
      checkLastDayOfTheWeek: false
    }
  },
  mounted() {
   this.getUser();
  },
  methods: {
    reset() {
       tt = "",d1="",d2="",d3="",d4="",d5="",d6="",d7="",d8="",d9="",d10="",d11="",d12="",d13="",d14="",d15="",
        d16="",d17="",d18="",d19="",d20="",d21="",d22="",d23="",d24="",d25="",d26="",d27="",d28="",d29="",d30="",d31="",
        td1="",td2="",td3="",td4="",td5="",td6="",td7="",td8="",td9="",td10="",td11="",td12="",td13="",td14="",td15="",
        td16="",td17="",td18="",td19="",td20="",td21="",td22="",td23="",td24="",td25="",td26="",td27="",td28="",td29="",td30="",td31="";
    },
    getUser() {
      axios
        .get(
          'api/get-user/' + this.$route.params.pID 
        )
        .then((response) => {
          console.log("users=>", response.data);
          console.log("response", response);

          let fname = response.data['first_name'];
          let lname = response.data['last_name'];
          let mname = response.data['middle_name'];
          let m = mname.charAt(0);
          let ffname = fname + ' ' + m + '.' + ' ' + lname;
          this.fullname = ffname.toUpperCase();

        })
        .catch((error) => {
          alert(error);
        });
    },
    reference() {

      let d = moment().format('L');
      let t = moment().format('LTS');

      return d + ' ' + t; 
    },
    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm A");
    },
    getDate() {
      let m = this.from; // get the month
      let f = this.from; // get date from
      let t = this.to; // get date to

      let month =  String(m[5]) + String(m[6]);
      let year = String(m[0]) + String(m[1]) + String(m[2]) + String(m[3]);
      let dayTo = "";
      let dayFrom = "";
      
      this.months = month;
      this.years = year;

      if(this.to) {
        dayTo = String(t[8]) + String(t[9]);
      }
      if(this.from) {
        dayFrom = String(f[8]) + String(f[9]);
      }

      let d = new Date();

      if(month === '01') {
        return "January" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '02') {
        return "February" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '03') {
        return "March" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '04') {
        return "April" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '05') {
        return "May" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '06') {
        return "June" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '07') {
        return "July" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '08') {
        return "August" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '09') {
        return "September" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '10') {
        return "October" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '11') {
        return "November" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '12') {
        return "December" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else {
        return "";
      } 

    },
    getCheckIN(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 

      let found = '';
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-In') {
          if(individual_log.time > "5:00" || individual_log.time < "11:59") {
            t = individual_log.time + "AM";
            found = t;
            console.log("asddddddd", t);
            return;
          } else {
            t = "";
          }
        }
      });
      return found;
    },

    checkDateForHide(date) {
      var dt = new Date(date);
      if(dt.getDay() == 5) {
        return true;
      } else {
        return false;

      }
    },
    totalWeeklyRenderHours1() {

      tt = moment.duration(d1)
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours2() {

      tt = moment.duration(td1).add(moment.duration(d2))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours3() {

      tt = moment.duration(td2).add(moment.duration(td3))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours4() {

      tt = moment.duration(td2).add(moment.duration(td3)).add(moment.duration(td4))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours5() {

      tt = moment.duration(td2).add(moment.duration(td3)).add(moment.duration(td4)).add(moment.duration(td5))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours6() {

      tt = moment.duration(td2).add(moment.duration(td3)).add(moment.duration(td4)).add(moment.duration(td5)).add(moment.duration(td6))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },

    totalWeeklyRenderHours7() {

      tt = moment.duration(td3).add(moment.duration(td4)).add(moment.duration(td5)).add(moment.duration(td6)).add(moment.duration(td7))
                moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

        return tt;
    },
    totalWeeklyRenderHours8() {

      tt = moment.duration(td4).add(moment.duration(td5)).add(moment.duration(td6)).add(moment.duration(td7)).add(moment.duration(td8))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours9() {

      tt = moment.duration(td5).add(moment.duration(td6)).add(moment.duration(td7)).add(moment.duration(td8)).add(moment.duration(td9))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours10() {

      tt = moment.duration(td6).add(moment.duration(td7)).add(moment.duration(td8)).add(moment.duration(td9)).add(moment.duration(td10))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours11() {

      tt = moment.duration(td7).add(moment.duration(td8)).add(moment.duration(td9)).add(moment.duration(td10)).add(moment.duration(td11))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours12() {

      tt = moment.duration(td8).add(moment.duration(td9)).add(moment.duration(td10)).add(moment.duration(td11)).add(moment.duration(td12))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours13() {

      tt = moment.duration(td9).add(moment.duration(td10)).add(moment.duration(td11)).add(moment.duration(td12)).add(moment.duration(td13))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours14() {

      tt = moment.duration(td10).add(moment.duration(td11)).add(moment.duration(td12)).add(moment.duration(td13)).add(moment.duration(td14))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours15() {

      tt = moment.duration(td11).add(moment.duration(td12)).add(moment.duration(td13)).add(moment.duration(td14)).add(moment.duration(td15))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours16() {

      tt = moment.duration(td12).add(moment.duration(td13)).add(moment.duration(td14)).add(moment.duration(td15)).add(moment.duration(td16))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours17() {

      tt = moment.duration(td13).add(moment.duration(td14)).add(moment.duration(td15)).add(moment.duration(td16)).add(moment.duration(td17))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours18() {

      tt = moment.duration(td14).add(moment.duration(td15)).add(moment.duration(td16)).add(moment.duration(td17)).add(moment.duration(td18))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours19() {

      tt = moment.duration(td15).add(moment.duration(td16)).add(moment.duration(td17)).add(moment.duration(td18)).add(moment.duration(td19))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours20() {

      tt = moment.duration(td16).add(moment.duration(td17)).add(moment.duration(td18)).add(moment.duration(td19)).add(moment.duration(td20))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours21() {

      tt = moment.duration(td17).add(moment.duration(td18)).add(moment.duration(td19)).add(moment.duration(td20)).add(moment.duration(td21))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours22() {

      tt = moment.duration(td18).add(moment.duration(td19)).add(moment.duration(td20)).add(moment.duration(td21)).add(moment.duration(td22))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours23() {

      tt = moment.duration(td19).add(moment.duration(td20)).add(moment.duration(td21)).add(moment.duration(td22)).add(moment.duration(td23))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours24() {

      tt = moment.duration(td20).add(moment.duration(td21)).add(moment.duration(td22)).add(moment.duration(td23)).add(moment.duration(td24))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours25() {

      tt = moment.duration(td21).add(moment.duration(td22)).add(moment.duration(td23)).add(moment.duration(td24)).add(moment.duration(td25))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours26() {

      tt = moment.duration(td22).add(moment.duration(td23)).add(moment.duration(td24)).add(moment.duration(td25)).add(moment.duration(td26))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours27() {

      tt = moment.duration(td23).add(moment.duration(td24)).add(moment.duration(td25)).add(moment.duration(td26)).add(moment.duration(td27))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours28() {

      tt = moment.duration(td24).add(moment.duration(td25)).add(moment.duration(td26)).add(moment.duration(td27)).add(moment.duration(td28))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours29() {

      tt = moment.duration(td25).add(moment.duration(td26)).add(moment.duration(td27)).add(moment.duration(td28)).add(moment.duration(td29))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours30() {

      tt = moment.duration(td26).add(moment.duration(td27)).add(moment.duration(td28)).add(moment.duration(td29)).add(moment.duration(td30))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },
    totalWeeklyRenderHours31() {

      tt = moment.duration(td27).add(moment.duration(td28)).add(moment.duration(td29)).add(moment.duration(td30)).add(moment.duration(td31))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss");

      return tt;
    },

    checkUnderTime(date1) {
      var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";

      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }

            if(dt.getDay() == 1) {
              if(individual_log.inOutStatus === 'Check-In') {
                      if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                        checkIn = individual_log.time;  
                        if(checkIn > "8:00:00") {
                          bs = "08:00:00"
                          e = new Date(d + " " + checkIn);
                          s = new Date(d + " " + bs);
                          dif = Math.abs(e - s);

                          
                        var minutes = Math.floor((dif / (1000 * 60)) % 60),
                          hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                          hours = (hours < 10) ? "0" + hours : hours;
                          minutes = (minutes < 10) ? "0" + minutes : minutes;

                          r1 = hours + ":" + minutes;
                        } 

                      }
                      x = moment.duration(r1).add(moment.duration(r2))
                          moment.utc(x.as('milliseconds')).format("HH:mm:ss")

              }
              if(individual_log.inOutStatus === 'Check-Out') {
                      if(individual_log.time > "13:00:00") {
                        checkOut = individual_log.time;  

                        
                        if(checkOut < "16:00:00") {
                          bs = "16:00:00"
                          e = new Date(d + " " + checkOut);
                          s = new Date(d + " " + bs);
                          dif = Math.abs(s - e);

                          
                        var minutes = Math.floor((dif / (1000 * 60)) % 60),
                          hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                          hours = (hours < 10) ? "0" + hours : hours;
                          minutes = (minutes < 10) ? "0" + minutes : minutes;

                          r2 = hours + ":" + minutes;
                        } else {
                          r2 = "00:00";
                        }

                      }
                      x = moment.duration(r1).add(moment.duration(r2))
                          moment.utc(x.as('milliseconds')).format("HH:mm:ss")
              }   
          } else {
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(this.$route.params.pID == 30) {
                        if(checkIn > "9:00:00") {
                          bs = "9:00:00"
                          e = new Date(d + " " + checkIn);
                          s = new Date(d + " " + bs);
                          dif = Math.abs(e - s);

                          
                        var minutes = Math.floor((dif / (1000 * 60)) % 60),
                          hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                          hours = (hours < 10) ? "0" + hours : hours;
                          minutes = (minutes < 10) ? "0" + minutes : minutes;

                          r1 = hours + ":" + minutes;
                        } 
                      } else {
                        if(checkIn > "8:30:00") {
                          bs = "8:30:00"
                          e = new Date(d + " " + checkIn);
                          s = new Date(d + " " + bs);
                          dif = Math.abs(e - s);

                          
                        var minutes = Math.floor((dif / (1000 * 60)) % 60),
                          hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                          hours = (hours < 10) ? "0" + hours : hours;
                          minutes = (minutes < 10) ? "0" + minutes : minutes;

                          r1 = hours + ":" + minutes;
                        } 
                      }


                    }

                    x = moment.duration(r1)
                        moment.utc(x.as('milliseconds')).format("HH:mm:ss")

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                      if(individual_log.time > "13:00:00") {
                        checkOut = individual_log.time;  

                        
                        if(checkOut < "16:00:00") {
                          bs = "16:00:00"
                          e = new Date(d + " " + checkOut);
                          s = new Date(d + " " + bs);
                          dif = Math.abs(s - e);

                          
                        var minutes = Math.floor((dif / (1000 * 60)) % 60),
                          hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                          hours = (hours < 10) ? "0" + hours : hours;
                          minutes = (minutes < 10) ? "0" + minutes : minutes;

                          r2 = hours + ":" + minutes;
                        } else {
                          r2 = "00:00";
                        }

                      }
                      x = moment.duration(r1).add(moment.duration(r2))
                          moment.utc(x.as('milliseconds')).format("HH:mm:ss")
              }

          }
      
      
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return x;
      }

    },
    totalRenderHours1(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d1 = x;
          td1 = x;

        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d1;

      }

    },
    totalRenderHours2(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d2 = x;
          td2 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d2;

      }

    },
    totalRenderHours3(date1) {
      var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d3 = x;
          td3 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d3;

      }

    },
    totalRenderHours4(date1) {
      var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d4 = x;
          td4 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d4;

      }

    },
    totalRenderHours5(date1) {
      var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d5 = x;
          td5 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d5;

      }

    },
    totalRenderHours6(date1) {
      var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d6 = x;
          td6 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d6;

      }

    },
    totalRenderHours7(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d7 = x;
          td7 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d7;

      }

    },
    totalRenderHours8(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d8 = x;
          td8 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d8;

      }

    },
    totalRenderHours9(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d9 = x;
          td9 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d9;

      }

    },
    totalRenderHours10(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d10 = x;
          td10 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d10;

      }

    },
    totalRenderHours11(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d11 = x;
          td11 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d11;

      }

    },
    totalRenderHours12(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d12 = x;
          td12 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d12;

      }

    },
    totalRenderHours13(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d13 = x;
          td13 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d13;

      }

    },
    totalRenderHours14(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d14 = x;
          td14 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d14;

      }

    },
    totalRenderHours15(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d15 = x;
          td15 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d15;

      }

    },
    totalRenderHours16(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d16 = x;
          td16 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d16;

      }

    },
    totalRenderHours17(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d17 = x;
          td17 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d17;

      }

    },
    totalRenderHours18(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d18 = x;
          td18 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d18;

      }

    },
    totalRenderHours19(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d19 = x;
          td19 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d19;

      }

    },
    totalRenderHours20(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d20 = x;
          td20 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d20;

      }

    },
    totalRenderHours21(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d21 = x;
          td21 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d21;

      }

    },
    totalRenderHours22(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d22 = x;
          td22 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d22;

      }

    },
    totalRenderHours23(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d23 = x;
          td23 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d23;

      }

    },
    totalRenderHours24(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d24 = x;
          td24 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d24;

      }

    },
    totalRenderHours25(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d25 = x;
          td25 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d25;

      }

    },
    totalRenderHours26(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d26 = x;
          td26 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d26;

      }

    },
    totalRenderHours27(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d27 = x;
          td27 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d27;

      }

    },
    totalRenderHours28(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d28 = x;
          td28 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d28;

      }

    },
    totalRenderHours29(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d29 = x;
          td29 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d29;

      }

    },
    totalRenderHours30(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d30 = x;
          td30 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d30;

      }

    },
    totalRenderHours31(date1) {
     var dt = new Date(date1), absent = 'true', d = moment().format('L'), x = "";
     let checkIn = "00:00:00", checkOut = "00:00:00", dif = "", s = "", e = "", bs = "", r1 = "00:00:00", r2 = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {

        if(individual_log.date === date1) {

          absent = false;

            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  

                    }
            }
      
            if(individual_log.inOutStatus === 'Check-In') {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "6:00:00") {
                        bs = "12:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r1 = hours + ":" + minutes;
                      } 

                    }

  
            }

            if(individual_log.inOutStatus === 'Check-Out') {
                    if(individual_log.time > "13:00:00" || individual_log.time < "24:00:00") {
                      checkOut = individual_log.time;  
                      if(checkOut > "13:00:00") {
                        bs = "13:00:00"
                        e = new Date(d + " " + checkOut);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;

                        r2 = hours + ":" + minutes;
                      } else {
                        r2 = "00:00:00";
                      }

                    }

  
            }          

          x = moment.duration(r1).add(moment.duration(r2))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d31 = x;
          td31 = x;
        }

      });

      if(absent == 'true') {
        return '';
      } else {
        return d31;
      }

    },
    getCheckInArrival(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 

      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-In') {
          if(individual_log.time > "12:00:00" && individual_log.time < "17:00:00") {
            t = individual_log.time;
          }             
          
        }
      });
      return t;

    },

    getCheckOUT(date1) {

      var dt = new Date(date1);
      let t = "", temp_time = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 


      this.individual_logs.forEach(individual_log => {
        
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-Out') {
              
                t = individual_log.time;
                t = t.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [t];
                if(t.length > 1) { // If time format correct
                      t = t.slice (1); // Remove full string match value
                      if(t[0] > 8 && t[0] <= 13) {
                        temp_time = individual_log.time;
                      }

                }
        }
      });
      return temp_time;
    },

    getCheckOUTHome(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 

      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-Out') {
          if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
            t = individual_log.time;
          }
        }
      });
      return t;
    },

    printForm() {
      // Pass the element id here
      this.$htmlToPaper('dtr');
    },

    tConvert (time) {
      // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
    },

    searchLogs(from, to) {
          this.reset();
      axios
        .get(
          'api/dtr-individual-logs/' + this.$route.params.pID + '/' + from + '/' + to
        )
        .then((response) => {
          console.log("individual logs=>", response.data);
          this.individual_logs = response.data;
          // console.log("response", response);
 
        })
        .catch((error) => {
          alert(error);
        });

    },
    convertToWords(digital) {
       let w =  digital.toString();
       let c = digital;
       if(w == "P0D") {
         c = "NO LATE";
       }
       return c;
     }
  }
}
</script>